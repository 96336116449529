// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit';

import popupReducer from './slices/popupSlice';

export const store = configureStore({
  reducer: {
    ipPopup: popupReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
