import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';

import { PopUpState } from '../types';

const initialState: PopUpState = {
  data: [],
  isPopupOpen: false,
};

export const popupSlice: Slice<PopUpState> = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    handlePopup: (state, action: PayloadAction<PopUpState>) => {
      state.isPopupOpen = action.payload.isPopupOpen;
      state.data = action.payload.data;
    },
  },
});

export const { handlePopup } = popupSlice.actions;

export default popupSlice.reducer;
