'use client';

import { ReactNode } from 'react';

import { ProvidersWithReactQuery } from './with-react-query';
import { ProvidersWithRedux } from './with-redux';

export default function Providers({ children }: { children: ReactNode }) {
  return (
    <ProvidersWithRedux>
      <ProvidersWithReactQuery>{children}</ProvidersWithReactQuery>
    </ProvidersWithRedux>
  );
}
