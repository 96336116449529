'use client';

import { ReactNode } from 'react';

import { EnhancedStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import { store as DEFAULT_STORE } from '../redux/store';

export default function ProvidersWithRedux({
  children,
  store,
}: {
  children: ReactNode;
  store?: EnhancedStore<unknown>;
}) {
  return <Provider store={store ?? DEFAULT_STORE}>{children}</Provider>;
}
